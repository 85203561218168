import bgPSItem01 from '@/assets/images/home/bg_p_s_item_01.jpg'
import bgPSItem02 from '@/assets/images/home/bg_p_s_item_02.jpg'
import bgPSItem03 from '@/assets/images/home/bg_p_s_item_03.jpg'
import bgASItem01 from '@/assets/images/home/bg_a_s_item_01.jpg'
import bgASItem02 from '@/assets/images/home/bg_a_s_item_02_n.jpg'
import bgASItem03 from '@/assets/images/home/bg_a_s_item_03.png'
import bgASItem04 from '@/assets/images/home/bg_a_s_item_04.jpg'
import bgASItem05 from '@/assets/images/home/bg_a_s_item_05.jpg'
import bgASItem06 from '@/assets/images/home/bg_a_s_item_06.jpg'
export const dataP_S = [
  {
    label: '校端产品',
    route: '/products-plans/school',
    img: bgPSItem01,
  },
  {
    label: '区域产品',
    route: '/products-plans/area',
    img: bgPSItem02,
  },
  {
    label: '解决方案',
    route: '/products-plans/solution',
    img: bgPSItem03,
  },
]

export const dataA_S = [
  {
    label: '学生个性成长',
    desc: 'Student Personality Growth',
    route: '/scene/student-grow',
    img: bgASItem01,
  },
  {
    label: '教师专业发展',
    desc: 'Teacher Professional Development',
    route: '/scene/teacher-development',
    img: bgASItem02,
  },
  {
    label: '教育管理与评价',
    desc: 'Education Management and Evaluation',
    route: '/scene/education-manage_evaluate',
    img: bgASItem03,
  },
  {
    label: '绿色校园',
    desc: 'Green Campus',
    route: '/scene/green_campus',
    img: bgASItem04,
  },
  {
    label: '平安校园',
    desc: 'Safe Campus',
    route: '/scene/safe_campus',
    img: bgASItem05,
  },
  {
    label: '社会服务',
    desc: 'Social Service',
    route: '/scene/social_service',
    img: bgASItem06,
  },
]
