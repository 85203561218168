<template>
  <div id="Home">
    <div class="banner">
      <video
        v-if="$store.state.deviceWidth > 769"
        :controls="false"
        class="bgVideo video"
        :style="{
          height: '100%',
        }"
        autoplay
        loop
        muted
        src="/videos/n2.mp4"
      ></video>
      <!--   安卓移动端浏览器识别到video标签会生成浏览器自己样式的video并且无法关闭，层级还是最高，此处准备用gif图代替   -->
      <img v-else alt="" class="video" src="@/assets/images/about/banner.png" />
      <el-dialog
        :lock-scroll="true"
        :modal-append-to-body="false"
        :visible.sync="dialogBannerVideoShow"
        :width="$store.state.deviceWidth > 768 ? '70%' : '100%'"
        class="dialogBannerVideo"
        top="10vh"
      >
        <!-- <video id="bannerVideo" :src="bannerVideoUrl" controls loop></video> -->
        <video id="bannerVideo" ref="videoPlayer" class="video-js"></video>
      </el-dialog>
      <h2>让教育更加高效而美好</h2>
      <i
        class="iconfont icon-triangle-right"
        @click.stop=";(dialogBannerVideoShow = true), initVideo()"
      ></i>
    </div>
    <ul class="numbers">
      <template v-if="!!Object.keys($store.state.baseInfo).length">
        <li>
          <!-- <img
            src="@/assets/images/home/area.svg"
            style="width: 52px; height: 52px"
            alt=""
          /> -->
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#icon-earth-svg"></use>
          </svg>
          <!-- <i class="iconfont icon-diyufenbu"></i> -->
          <span>全国覆盖省份</span>
          <b>
            <span data-num="31" class="num-flip"></span>
            <span>个</span>
          </b>
        </li>
        <li>
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#icon-coin-rotate-svg"></use>
          </svg>
          <span>千万/亿级融资</span>
          <b> 连续<span data-num="6" class="num-flip"></span>年 </b>
        </li>
        <li>
          <!-- <img
            src="@/assets/images/home/company.svg"
            style="width: 55px; height: 55px"
            alt=""
          /> -->
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#icon-buildings-svg"></use>
          </svg>
          <!-- <i class="iconfont icon-zonggongsi"></i> -->
          <span>旗下子公司</span>
          <b>
            <span :data-num="18" class="num-flip"></span>
            <span>个</span>
          </b>
        </li>
        <li>
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#icon-school-svg"></use>
          </svg>
          <span>服务学校</span>
          <b>
            <span
              :data-num="$store.state.baseInfo['school']"
              class="num-flip"
            ></span>
            <span>{{ $store.state.baseInfo['schoolUnit'] }}+</span>
          </b>
        </li>
        <li>
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#icon-users-svg"></use>
          </svg>
          <span>服务师生</span>
          <b>
            <span
              :data-num="$store.state.baseInfo['teach_student']"
              class="num-flip"
            ></span>
            <span>{{ $store.state.baseInfo[`teach_studentUnit`] }}</span
            >+
          </b>
        </li>
        <li>
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#icon-calendar-clock-svg"></use>
          </svg>
          <span>编排课表</span>
          <b>
            <span
              :data-num="$store.state.baseInfo['timetable']"
              class="num-flip"
            ></span>
            <span>{{ $store.state.baseInfo['timetableUnit'] }}</span
            >+
            <!-- <sub> +</sub> -->
          </b>
        </li>
      </template>
    </ul>
    <section class="box p_s qzsj">
      <div class="title">
        <h2>全栈数据融通产品生态</h2>
        <!-- <p>Full-stack data integration product ecology</p> -->
      </div>
      <div class="hideLogo">
        <img src="@/assets/images/home/logo.jpg" alt="" />
      </div>
      <div class="p_s_box container">
        <ul class="">
          <li
            v-for="(item, index) in dataP_S"
            :key="index"
            @click.stop="$router.push(item.route)"
          >
            <div>
              <img :src="item.img" alt="" />
              <span>{{ item.label }}</span>
              <span>
                了解更多
                <i class="iconfont icon-arrow-right"></i>
              </span>
            </div>
          </li>
        </ul>
        <img
          alt=""
          src="@/assets/images/home/link.png"
          class="link"
          @click="toLink()"
        />
        <div class="link2" @click="toLink()">
          <img src="@/assets/images/home/link2.png" class="bg" />
          <div class="text">
            <img src="@/assets/images/home/right.png" class="icon" />
            项目咨询
          </div>
        </div>
      </div>
    </section>
    <section class="box a_s">
      <div class="title">
        <h2>应用场景</h2>
        <!-- <p>Application Scenario</p> -->
      </div>
      <ul class="a_s_box container">
        <li
          v-for="(item, index) in dataA_S"
          :key="index"
          @click.stop="$router.push(item.route)"
        >
          <img :src="item.img" alt="" />
          <div class="img__hover"></div>
          <div>
            <h3
              v-if="
                item.label === '平安校园与后勤管理' &&
                $store.state.deviceWidth < 1000
              "
            >
              平安校园
            </h3>
            <h3 v-else>{{ item.label }}</h3>
            <!-- <p>{{ item.desc }}</p> -->
          </div>
        </li>
      </ul>
    </section>
    <section class="box s_s">
      <div class="title">
        <h2>服务与支持</h2>
        <!-- <p>Service And Support</p> -->
      </div>
      <div class="s_s_box container">
        <div class="inner">
          <div class="bottom">
            <div>
              <h3>服务解读</h3>
              <!-- <p>什么是Service 2.0?</p> -->
            </div>
            <el-button
              type="primary"
              @click.stop="$router.push('/service#interpretaion')"
              >了解更多
            </el-button>
          </div>
        </div>
      </div>
    </section>
    <section class="box j_s">
      <div class="title">
        <h2>投资机构</h2>
        <!-- <p>Investment Organization</p> -->
      </div>
      <div class="container j_s_box">
        <ul>
          <li>
            <img class="pc" alt="" src="@/assets/images/home/yunqiziben1.png" />
            <img
              class="mobile"
              alt=""
              src="@/assets/images/home/yunqiziben1.png"
            />
          </li>
          <li>
            <img class="pc" alt="" src="@/assets/images/home/amibaziben1.png" />
            <img
              class="mobile"
              alt=""
              src="@/assets/images/home/amibaziben1.png"
            />
          </li>
          <li>
            <img class="pc" alt="" src="@/assets/images/home/xindongfang.png" />
            <img
              class="mobile"
              alt=""
              src="@/assets/images/home/xindongfang_m.png"
            />
          </li>
          <li>
            <img
              class="pc"
              alt=""
              src="@/assets/images/home/zijietiaodong.png"
            />
            <img
              class="mobile"
              alt=""
              src="@/assets/images/home/zijietiaodong_m.png"
            />
          </li>
          <li>
            <img
              class="pc"
              alt=""
              src="@/assets/images/home/huachuangziben.png"
            />
            <img
              class="mobile"
              alt=""
              src="@/assets/images/home/huachuangziben_m.png"
            />
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { Flip } from 'number-flip'
import { dataP_S, dataA_S } from '@/data/home'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
  name: 'Home',
  metaInfo: {
    title: '晓羊集团-数据融通的全栈产品生态-让教育更加高效而美好！',
    meta: [
      {
        name: 'keywords',
        content:
          '智慧AI云屏，智慧云黑板,互动课堂，教师发展，智慧研学，智慧校园，教育评价，教育信息化解决方案',
      },
      {
        name: 'description',
        content:
          '晓羊集团，作为专注的智慧教育全栈产品与服务提供商，打造数据融通的全栈产品生态，为教育行业提供高效智能产品与服务，创造性地帮助教育行政机关、学校以及教师全面提升管理和专业发展水平，以智慧教育生态全面赋能教育发展。',
      },
    ],
  },
  data() {
    return {
      player: null,
      animations: [
        {
          query: '.numbers',
          fn: 'numberFlip',
          completed: false,
        },
      ], // 需要去展示的动画
      dialogBannerVideoShow: false, //完整视频的播放地址
      bannerVideoUrl: `${process.env.VUE_APP_CDN}video/video_banner.mp4`, // banner视频地址
      // 产品和解决方案数据
      dataP_S,
      // 应用场景数据
      dataA_S,
    }
  },
  computed: {},
  watch: {
    // 打开关闭视频弹窗
    dialogBannerVideoShow(newValue) {
      this.$nextTick(() => {
        if (newValue) {
          this.player.play()
        } else {
          this.player.pause()
        }
      })
    },
    /**
     * 监听智能施放动画
     */
    '$store.state.scrollTop'() {
      this.animations.forEach(item => {
        const el = document.querySelector(item.query)
        const inViewer = el ? this.$methods.isElementInViewer(el) : false
        if (inViewer && !item.completed) {
          item.completed = true
          this[item.fn]()
        }
      })
    },
  },
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  methods: {
    initVideo() {
      this.$nextTick(() => {
        this.player = videojs(this.$refs.videoPlayer, {
          autoplay: true,
          controls: true,
          loop: true,
          sources: [
            {
              src: '/videos/video_banner_1.mp4',
            },
          ],
          preload: 'metadata',
          height: 640,
        })
      })
    },
    /**
     * 数字翻滚
     */
    numberFlip() {
      const mainFn = () => {
        document.querySelectorAll('.num-flip').forEach(item => {
          new Flip({
            node: item,
            from: 0,
            to: Number(item.dataset.num),
            duration: 1.5,
            direct: false,
            separator: ',',
          })
        })
      }
      const interval = setInterval(() => {
        if (document.querySelectorAll('.num-flip').length) {
          clearInterval(interval)
          mainFn()
        }
      }, 500)
    },
    toLink() {
      this.$router.push('/consult')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/pages/home.scss';
</style>
